import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Navbar from "../templates/navbar";

import {
  format,
  getYear,
  getMonth,
  isPast,
  isFuture,
  subDays,
  addDays,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import { useEffect } from "react";

const cardStyle = {
  height: "100%",
  display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
};

const monthStyle = {
  fontSize: "2rem",
  fontWeight: "bold",
  padding: "2rem 1rem",
  textTransform: "capitalize",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
};

const btnStyle = {
  margin: ".5rem .3rem",
};

const selectStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
};

const fromStyle = {
  position: "absolute",
  width: "2.5rem",
  right: "0",
  top: "0",
};

const tabsStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "1rem",
};

const entireFlex = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const imageStyle = {
  margin: "1rem",
  cursor: "pointer",
};

// markup
const IndexPage = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterLocation, setfilterLocation] = useState("");
  const [currentTab, setCurrentTab] = useState("next");
  const [openCompModal, setOpenCompModal] = useState([]);
  const [comps, setComps] = useState([]);

  let tags = [...new Set(comps.map((item) => item.location.split(" - ")[0]))];

  useEffect(() => {
    setComps(
      data.comps.edges
        .map((x) => ({
          ...x.node,
          startDate: x.node.isMilli
            ? Number(x.node.startDate)
            : x.node.startDate,
          endDate: x.node.isMilli ? Number(x.node.endDate) : x.node.endDate,
        }))
        .filter(
          (x) =>
            (currentTab === "next" &&
              isFuture(
                addDays(new Date(x.endDate ? x.endDate : x.startDate), 1)
              )) ||
            (currentTab === "previous" &&
              isPast(addDays(new Date(x.endDate ? x.endDate : x.startDate), 1)))
        )
    );
  }, []);

  const groupBy = (key) => {
    return (array) =>
      array.reduce((objectsByKeyValue, obj) => {
        let value = obj[key];
        value = format(
          new Date(getYear(new Date(value)), getMonth(new Date(value))),
          "MMMM yyyy",
          { locale: ptBR }
        );
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
  };

  let group = groupBy("startDate")(comps);
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleFilterLocationChange = (event) => {
    setfilterLocation(event.target.value);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Language" content="pt-br" />
        <title>Campeonatos Horse Club</title>
        <link rel="canonical" href="https://campeonatos.horsecs.com.br" />
        <meta property="og:url" content="https://campeonatos.horsecs.com.br" />
        <meta property="og:title" content="Campeonatos Horse Club" />
        <meta
          property="og:description"
          content="Encontre aqui todas as campeonatos de 3 Tambores no país!"
        />
        <meta
          property="og:image"
          content={data.logo.childImageSharp.fluid.src}
        />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1184493445900002"
     crossorigin="anonymous"></script>
      </Helmet>
      <Navbar />
      <section className="hero is-info is-fullheight">
        <div className="hero-head">
          <div className="hero-body">
            <div className="container has-text-centered">
              <div className="column is-6 is-offset-3">
                <h1 className="title">
                  <img
                    src={data.logo.childImageSharp.fluid.src}
                    alt="Horse Club Logo"
                    style={{ height: "8rem" }}
                  />
                </h1>
                <h2 className="subtitle">
                  Encontre aqui todas as campeonatos de 3 Tambores no país!{" "}
                  <br />
                  (Ainda em desenvolvimento)
                </h2>
                <div className="box">
                  <div className="field is-grouped">
                    <p className="control is-expanded">
                      <input
                        className="input"
                        type="text"
                        placeholder="Procure por título"
                        value={searchTerm}
                        onChange={handleChange}
                      />
                    </p>
                  </div>
                </div>
                <div className="column" style={selectStyle}>
                  <span style={{ paddingRight: "1rem" }}>
                    Ver próximas campeonatos em
                  </span>
                  <div
                    className="select is-info is-small"
                    style={{ width: "8rem" }}
                  >
                    <select
                      value={filterLocation}
                      onChange={handleFilterLocationChange}
                    >
                      <option value={""} defaultValue="selected">
                        Todos os Locais
                      </option>
                      {tags
                        .sort((a, b) => (a !== b ? (a < b ? -1 : 1) : 0))
                        .map((x, i) => (
                          <option key={i} value={x}>
                            {x}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="rows">
                <div className="row">
                  <div
                    className="title is-5"
                    style={{ ...monthStyle, color: "white" }}
                  >
                    Campeonatos Em Destaque
                  </div>
                </div>
                <div className="row">
                  <div
                    className="tags are-small"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <figure
                      className="image is-128x128"
                      style={{ ...entireFlex, ...imageStyle }}
                      onClick={() =>
                        setOpenCompModal(
                          comps
                            .filter((x) =>
                              x.title.toLowerCase().includes("copa avaré")
                            )
                            .sort(
                              (a, b) =>
                                new Date(a.startDate) - new Date(b.startDate)
                            )
                        )
                      }
                    >
                      <img src={data.copaAvare.childImageSharp.fluid.src} />
                    </figure>
                    <figure
                      className="image is-128x128"
                      style={{ ...entireFlex, ...imageStyle }}
                      onClick={() =>
                        setOpenCompModal(
                          comps
                            .filter((x) =>
                              x.title.toLowerCase().includes("super semana")
                            )
                            .sort(
                              (a, b) =>
                                new Date(a.startDate) - new Date(b.startDate)
                            )
                        )
                      }
                    >
                      <img src={data.superSemana.childImageSharp.fluid.src} />
                    </figure>
                    <figure
                      className="image is-128x128"
                      style={{ ...entireFlex, ...imageStyle }}
                      onClick={() =>
                        setOpenCompModal(
                          comps
                            .filter((x) =>
                              x.title
                                .toLowerCase()
                                .includes("circuito barrel race")
                            )
                            .sort(
                              (a, b) =>
                                new Date(a.startDate) - new Date(b.startDate)
                            )
                        )
                      }
                    >
                      <img
                        src={data.circuitoBarrelRace.childImageSharp.fluid.src}
                      />
                    </figure>
                    <figure
                      className="image is-128x128"
                      style={{ ...entireFlex, ...imageStyle }}
                    >
                      <img src={data.anuncie.childImageSharp.fluid.src} />
                    </figure>
                    {/* {comps
                      .filter(
                        (x) =>
                          x.title.toLowerCase().includes("nbha") ||
                          x.title
                            .toLowerCase()
                            .includes("circuito barrel race") ||
                          x.title.toLowerCase().includes("copa avaré")
                      )
                      .sort(
                        (a, b) => new Date(a.startDate) - new Date(b.startDate)
                      )
                      .map((x) => (
                        <span className="tag">
                          <Link to={`/competitions/${x.id}`}>{x.title}</Link>
                        </span>
                      ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container" style={{ marginTop: "0vh" }}>
        <div className="section">
          <div className="rows tabs" style={tabsStyle}>
            <div className="row">
              <div className="tabs is-toggle is-toggle-rounded">
                <ul>
                  <li
                    className={currentTab === "next" ? "is-active" : null}
                    onClick={() => setCurrentTab("next")}
                  >
                    <a>
                      <span className="icon is-small">
                        <i className="fas fa-image"></i>
                      </span>
                      <span>Próximas Competições</span>
                    </a>
                  </li>
                  <li
                    className={currentTab === "previous" ? "is-active" : null}
                    onClick={() => setCurrentTab("previous")}
                  >
                    <a>
                      <span className="icon is-small">
                        <i className="fas fa-music"></i>
                      </span>
                      <span>Competições Anteriores</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          {Object.entries(group)
            .sort(
              (a, b) =>
                new Date(a[1][0].startDate).getTime() -
                new Date(b[1][0].startDate).getTime()
            )

            .map((date, i) => (
              <div className="rows" key={i}>
                <div className="row">
                  <h1 style={{ ...monthStyle, color: "black" }}>{date[0]}</h1>
                </div>
                <div className="row columns is-multiline">
                  {date[1]
                    .filter(
                      (x) =>
                        (searchTerm == ""
                          ? true
                          : x.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())) &&
                        (filterLocation == ""
                          ? true
                          : x.location
                              .toLowerCase()
                              .includes(filterLocation.toLowerCase()))
                    )
                    .map((x) => {
                      return (
                        <div className="column is-4" key={x.title}>
                          <div className="card large" style={cardStyle}>
                            <div
                              className="card-content"
                              style={{ padding: "1rem 2rem" }}
                            >
                              <div className="media" style={{ height: "100%" }}>
                                <div className="media-left">
                                  <figure className="image is-48x48">
                                    <img
                                      src={
                                        x.image
                                          ? x.image
                                          : data.placeholder.childImageSharp
                                              .fluid.src
                                      }
                                      alt={`Logo de ${x.title}`}
                                    />
                                  </figure>
                                </div>

                                <div
                                  className="media-content"
                                  style={contentStyle}
                                >
                                  <p className="title is-4 no-padding">
                                    <Link to={`/competitions/${x.id}`}>
                                      {x.title}
                                    </Link>
                                    <br />
                                    <span
                                      className="title is-6"
                                      style={{ paddingBottom: "1rem" }}
                                    >
                                      {format(
                                        new Date(x.startDate),
                                        "dd MMMM yyyy",
                                        { locale: ptBR }
                                      )}{" "}
                                      {x.endDate
                                        ? "até " +
                                          format(
                                            new Date(x.endDate),
                                            "dd MMMM yyyy",
                                            { locale: ptBR }
                                          )
                                        : null}
                                    </span>
                                  </p>

                                  <p className="subtitle is-6">
                                    {x.location}, {x.city} - {x.state}
                                  </p>
                                  <div className="row">
                                    {x.map ? (
                                      <a
                                        className="button is-link"
                                        style={btnStyle}
                                        href={x.map}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Mapa
                                      </a>
                                    ) : null}
                                    {x.folder ? (
                                      <a
                                        className="button is-success"
                                        style={btnStyle}
                                        href={x.folder}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Folder
                                      </a>
                                    ) : null}
                                    <a
                                      className="button is-warning"
                                      style={btnStyle}
                                      href={
                                        x.subscription
                                          ? x.subscription
                                          : "https://seqm.com.br"
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Inscrições
                                    </a>
                                    {!isPast(
                                      addDays(
                                        new Date(
                                          x.endDate ? x.endDate : x.startDate
                                        ),
                                        1
                                      )
                                    ) && x.live ? (
                                      <a
                                        className="button is-primary is-fullwidth"
                                        style={btnStyle}
                                        href={x.live}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Ver Ao Vivo
                                      </a>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="from" style={fromStyle}>
                                  {x.from === "seqm" ? (
                                    <img
                                      src={
                                        data.logoSEQM.childImageSharp.fluid.src
                                      }
                                      alt="Logo SEQM"
                                    />
                                  ) : null}
                                  {x.from === "sgp" ? (
                                    <img
                                      src={
                                        data.logoSGP.childImageSharp.fluid.src
                                      }
                                      alt="Logo SGP"
                                    />
                                  ) : null}
                                  {x.from === "hcs" ? (
                                    <img
                                      src={
                                        data.logoHCS.childImageSharp.fluid.src
                                      }
                                      alt="Logo HCS"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ))}
        </div>
      </section>
      <div className={`modal ${openCompModal.length > 0 ? "is-active" : null}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Etapas </p>
              <button
                className="delete"
                onClick={() => setOpenCompModal([])}
              ></button>
            </header>
            <section className="modal-card-body">
              <ul className="block-list has-radius is-info  is-centered">
                {openCompModal.map((x) => (
                  <li>
                    <Link
                      style={{ color: "white" }}
                      to={`/competitions/${x.id}`}
                    >
                      {x.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "horse-club.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    logoSGP: file(relativePath: { eq: "logo-sgp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    logoSEQM: file(relativePath: { eq: "logo-seqm.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    logoHCS: file(relativePath: { eq: "hcs-square.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    placeholder: file(relativePath: { eq: "hcs-square.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    superSemana: file(relativePath: { eq: "super-semana.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    circuitoBarrelRace: file(relativePath: { eq: "circuito-barrel-race.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    copaAvare: file(relativePath: { eq: "copa-avare.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    anuncie: file(relativePath: { eq: "anuncie.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    comps: allRestApiV1Competition {
      edges {
        node {
          id
          registerId
          title
          startDate
          endDate
          image
          city
          state
          location
          folder
          manager
          judge
          subscription
          map
          modality
          from
          live
          isMilli
        }
      }
    }
  }
`;

export default IndexPage;
